import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import decodeHtmlCharCodes from "../utils/decodeHtmlCharCodes"
import { HugeMobileFont, BigMobileFont } from "./typography"
import { joinMenuToLocation } from "../helpers"

const Menu = styled.ul`
  margin-left: 0;
  padding-left: 2rem;
  border-left: 1px ${(props) => props.theme.secondary} solid;
`

const MenuItem = styled.li`
  font-weight: bold;
  display: block;
  white-space: nowrap;
  margin-bottom: 1.5rem;
  @media screen and (max-width: 768px) {
    white-space: normal;
  }
`
const MenuTitle = styled.p`
  display: inline-block;
  font-weight: 700;
  font-family: "Montserrat";
  padding: 0 0 1.5rem 0;
  color: ${(props) => props.theme.secondary};
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`
const MenuBottomTitle = styled.h1`
  display: inline-block;
  font-weight: 700;
  font-family: "Montserrat";
  padding: 1.5rem 0;
  color: ${(props) => props.theme.secondary};
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const HomapageMenuWrapper = styled.div`
  margin-bottom: 5%;
  flex: 40rem 1 0;
  margin-right: -10rem;
  @media screen and (max-width: 900px) {
    flex: auto 1 1;
    margin-right: 0;
  }
`

const HomepageMenu = ({ menuTitle, bottomTitle }) => {
  const {
    allWordpressWpApiMenusMenusItems,
    allWordpressWpApiMenusMenuLocations,
  } = useStaticQuery(
    graphql`
      query {
        allWordpressWpApiMenusMenuLocations(
          filter: { label: { eq: "Services" } }
        ) {
          nodes {
            label
            wordpress_id
          }
        }
        allWordpressWpApiMenusMenusItems {
          nodes {
            wordpress_id
            items {
              order
              title
              url
            }
          }
        }
      }
    `
  )
  const servicesMenu = joinMenuToLocation(
    allWordpressWpApiMenusMenuLocations.nodes[0],
    allWordpressWpApiMenusMenusItems.nodes
  )
  return (
    <HomapageMenuWrapper>
      <MenuTitle>
        <HugeMobileFont>{menuTitle}</HugeMobileFont>
      </MenuTitle>
      <Menu>
        {servicesMenu.items.map((menuItem) => (
          <MenuItem key={menuItem.title}>
            <BigMobileFont>
              <Link to={menuItem.url.replace(/http.*\/\/.*?\//g, "/")}>
                {decodeHtmlCharCodes(menuItem.title)}
              </Link>
            </BigMobileFont>
          </MenuItem>
        ))}
      </Menu>
      <MenuBottomTitle>
        <HugeMobileFont style={{ fontSize: "2rem", color: "#323e5b" }}>
          {bottomTitle}
        </HugeMobileFont>
      </MenuBottomTitle>
    </HomapageMenuWrapper>
  )
}

export default HomepageMenu
