import React from "react"
import { Container, BorderedHeader } from "./styledComponents"
import BorderedHeaderWrapper from "./borderedHeaderWrapper"
import ClientsSlider from "./sliders/clientsSlider"
import styled from "styled-components"
import { BigMobileFont } from "./typography"

const ClientsSectionWrapper = styled.div`
  display: flex;
  width: 85%;
  margin: 120px auto 90px;
  @media screen and (max-width: 1195px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`

const ClientsSection = ({ title }) => {
  return (
    <Container>
      <ClientsSectionWrapper>
        <BorderedHeaderWrapper size="medium">
          <BorderedHeader>
            <BigMobileFont desktopFontSize="2.5rem">{title}</BigMobileFont>
          </BorderedHeader>
        </BorderedHeaderWrapper>
        <ClientsSlider />
      </ClientsSectionWrapper>
    </Container>
  )
}

export default ClientsSection
